<template>
  <div class="bodycont">

    <comHeader />
   <!-- <div class="main">
      <navTitle :navData='navData' />
    </div> -->
    <!-- 成果列表展示页面 -->
    <div class="pcont">
      <div class="rightcont">
         <infonavtab :cindex='cindex'/>
      </div>
    <div class="leftcont">
      <div class="search">
        <div class="demo-input-suffix">
         团队名称：
           <el-input style="width: 200px;"  v-model="searchValue" clearable @clear='clear'></el-input>
           </div>
           <div class="demo-input-suffix">
            <el-button type="primary" @click="searchBtn">搜索</el-button>
           </div>
      </div>
      <div class="lcont" v-if="totalSize>0">
        <div class="allcont">
            <div class="continfo" v-for="item in dataList">
             <div class="imgdiv">
               <img :src="item.file?base_url+item.file:nodata"/>
             </div>

             <div class="infobox" @click="tourl(item)">
               <h3>{{item.objectName}}</h3>
               <p class="pArea"><span>地址:</span>{{item.objectArea}}</p>
                <p class="infos"><span>学科领域:</span>{{item.subject}}</p>
                 <p class="infos"><span>应用领域:</span>{{item.application}}</p>
              </div>
            </div>
        </div>

      </div>
      <div class="pages" v-if="totalSize>10">
        <el-pagination background layout="prev, pager, next" :current-page="page.page" :page-size="page.limit"
        	 :total="totalSize" @current-change="handleCurrentChange"></el-pagination>

      </div>
    </div>


  </div>


  <bNav />
  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
  import classifyType from '@/components/classify.vue'
  import navTitle from '@/components/navtitle.vue'
  import infonavtab from './components/navtab.vue'
  import api from '@/config/api'
  import {
    getRequst,postRequest
  } from '@/api/index'
  export default {
    components: {
      comHeader,
      bNav,
      classifyType,
      navTitle,
      infonavtab
    },
    data() {
      return {
        base_url:api.base_url,
        searchValue:'',
        dataList: [ ],
        cindex:3,
        page:{
          page:1,
          limit:20,
        },
        totalSize:0,
         nodata:require('../../assets/images/infonodata.png'),
      }
    },
    created() {

    },
    mounted() {
      this.getList()
    },
    methods: {
      searchBtn(){
        this.getList()
      },
      clear(){
        this.searchValue = ''
        this.getList()
      },
      //获取列表
      getList() {
        let data = {
          page:this.page.page,
          limit:this.page.limit,
          typeId:2,
        }
       data.objectName=this.searchValue
       postRequest(api.teamList, data).then(res => {
            this.dataList = res.page.list
             this.totalSize = Number(res.page.totalPage)
             if(this.totalSize==0){
               this.$message({
                 type: 'info',
                 message: '暂无相关数据'
               });
             }
         })

       },
       handleCurrentChange(val) {
         this.page.page = val;
         this.getList()
       },
      //查看详情
      tourl(data) {
        this.$router.push({
          path: '/teamDetails',
          query: {
            name:'创新团队',
            url:'teamList',
            id:data.objectId,
          },
        });
      },
      //切换分类筛选
      changindex(index){
        this.cindex = index
      }
    }
  }
</script>

<style lang="less" scoped>
  .bodycont {
    // background: url('../../assets/images/homebg1.png') repeat-x;
    width: 100%;
  }

  .main {
    width: 1180px;
    margin: 0px auto 0;
  }

  .site_h2 {
    height: 80px;
    width: 350px;
    margin: 0 auto;
    line-height: 80px;
    text-align: center;

    span {
      font-size: 24px;
      padding: 0 15px;
      background: #fff;
    }
  }

  .pages {
    margin: 20px auto;
    text-align: center;
  }

  .cont1 {
    min-height: 300px;
  }

  .pcont {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width:1190px;
    font-size: 14px;
    margin: 20px auto 0;
    min-height:75vh;

    .rightcont {
      width: 200px;
    }

    .leftcont {
      width: 80%;
      margin: 0 auto;

      .lcont {

      }
    }

    .continfo {
      display: flex;
      justify-content: space-between;
      width: 49%;
      margin-bottom: 20px;
      position: relative;
      background-color: #f0f8fc;
      padding: 20px 20px 10px;
      border-radius: 6px;
      cursor: pointer;
      // height: 190px;
      .imgdiv{
       width: 110px;
       height: 110px;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
           border-radius: 50%;
        }
      }


      .infobox {
        margin-left: 10px;
        margin-top: 10px;
        width: 70%;

        h3 {
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 10px;
          overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
        }

        p {
          font-size: 14px;
          color: #666;
          margin-bottom: 6px;

          span {
            color: #000;
          }
        }

        .infos {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-height: 20px;
        }
      }
    }
  }

  .cinfo {
    border: 1px solid #00A0E9;
    padding: 6px;
    color:#00A0E9;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
  }
  .search{
    display: flex;
     justify-content: flex-start; 
    margin-bottom: 20px;
    .demo-input-suffix{
      margin-right: 20px;
    }
  }


  .news_list {
    width: 150px;
    overflow: hidden;
    float: left;

    .top_title {
      width: 100%;
      height: 54px;
      background: url(../../assets/images/title.png) no-repeat;
      text-align: center;

      h4 {
        color: #fff;
        font-size: 18px;
        line-height: 54px;
      }
    }

    .zczx {
      padding: 10px 0px;
      background: #fff;

      .cur{
        background-color: #00428E;
        color: #fff;
      }

      li {
          font-size: 16px;
          color: #333;
          background: #eee;
          display: block;
          width: 100%;
          height: 40px;
          line-height: 40px;
          margin-bottom: 2px;
          text-align: center;
          padding-left: 0;
          border: none;
          position: relative;
          cursor: pointer;
      }
    }
  }
  .lcont{
  }
  .allcont{
    // min-height: 70vh;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .pArea{
    overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 20px;
        span{
           color: #000;
        }
  }
</style>
